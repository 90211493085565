import React, { Component } from "react";
import "../../scss/components/workers/CandidateComponent.scss";
import { Moment } from "moment";
import { phoneNumberWithoutCountryCode } from "../../modules/strings/phoneNumberWithoutCountryCode";
import { EAvailableLang } from "../../lang/index";
import chooseLanguage from "../../modules/toolbox/utils";

interface IWorker {
    firstName?: string;
    lastName?: string;
    id?: number;
    phone?: string;
}
interface IPropsCandidateComponent {
    worker: IWorker;
    date?: Moment;
    handleClick: () => void;
    lang?: any;
    currentLang?: EAvailableLang;
}
export default class CandidateComponent extends Component {
    props: IPropsCandidateComponent = {
        worker: {},
        handleClick: (): void => {}
    };
    render(): JSX.Element {
        return (
            <div className="candidate-component has-padding-bottom has-padding-top" onClick={this.props.handleClick}>
                <div className="row has-padding-left has-padding-right is-medium">
                    <div className="col-2 has-padding-bottom has-padding-top">
                        <img
                            className="img-fluid rounded-circle"
                            src="https://medgostatic.s3.eu-west-3.amazonaws.com/placeholder_pic.png"
                            alt="profile picture"
                        />
                    </div>
                    <div className="col-10 has-padding-bottom has-padding-top">
                        {this.props.worker.lastName && this.props.worker.firstName ? (
                            <p className="text-white">
                                <strong>
                                    {this.props.worker.firstName} {this.props.worker.lastName.toUpperCase()}
                                </strong>
                            </p>
                        ) : null}
                        {this.props.date && (
                            <p className="text-white">
                                {chooseLanguage(this.props.lang, this.props.currentLang)
                                    .missionAcceptedOnAt.replace("__VAR1", this.props.date.format("DD MMMM YYYY"))
                                    .replace("__VAR2", this.props.date.format("HH:mm"))}
                            </p>
                        )}
                        <p className="text-white">
                            {chooseLanguage(this.props.lang, this.props.currentLang).phone} :{" "}
                            {this.props.worker.phone
                                ? phoneNumberWithoutCountryCode(this.props.worker.phone)
                                : chooseLanguage(this.props.lang, this.props.currentLang).noNumber}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
