import React from "react";
import HubloSpinnerLoading from "../spinner/HubloSpinnerLoading.component";
import HubloErrorMessage from "./HubloErrorMessage.component";
import HubloMessageInfo from "./HubloMessageInfo.component";
import "../../scss/components/buttons/ButtonMedgoAction.scss";

interface IPropsHubloErrorMessageInfoBox {
    infoMessage: string;
    errorMessage: string;
    isLoading: boolean;
    lang: any;
    nextPage: () => void;
}

class HubloErrorMessageInfoBox extends React.Component<IPropsHubloErrorMessageInfoBox> {
    render(): JSX.Element {
        const message = this.props.infoMessage || this.props.errorMessage;

        return (
            <div className="m-auto">
                {!message && <br />}

                {message && (
                    <div>
                        {this.props.infoMessage && <HubloMessageInfo infoMessage={this.props.infoMessage} />}
                        <HubloErrorMessage label={this.props.errorMessage} />
                    </div>
                )}

                <div className="margin-top-s">
                    {this.props.isLoading === true ? (
                        <div className={`btn btn-primary btn-lg btn-block`}>
                            {" "}
                            <HubloSpinnerLoading isLoading={this.props.isLoading} color="white" />
                        </div>
                    ) : (
                        <button className={`btn btn-full btn-lg btn-block`} onClick={this.props.nextPage}>
                            {this.props.lang.save}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default HubloErrorMessageInfoBox;
