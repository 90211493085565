import React from "react";
import { LangConstants, DefaultLangConstant } from "../../constant/International.constant";
import { SelectTitleSingleValue } from "./SelectTitleSingleValue.view";
import { EAvailableLang } from "../../constant/International.constant";

interface IPropsSelectLanguage {
    currentLang: string;
    whitelist?: number[];
    lang: { language: string; noResult: string };
    handleChangeLang: (lang: EAvailableLang) => void;
}

export default class SelectLanguage extends React.Component<IPropsSelectLanguage> {
    render() {
        let options = LangConstants.map((e) => ({
            label: `${e.countryFlag} ${e.name}`,
            value: e.idLanguage
        }));
        const whitelist = this.props.whitelist;
        const LangConstant = LangConstants.find((e) => this.props.currentLang === e.lang);
        if (whitelist && whitelist.length) {
            options = options.filter((e) => whitelist.includes(e.value));
        }
        return (
            <SelectTitleSingleValue
                id="idLanguage"
                value={LangConstant ? LangConstant.idLanguage : DefaultLangConstant.idLanguage}
                options={options}
                onSelectValue={(_idLanguage: number): void => {
                    const _LangConstant = LangConstants.find((e) => _idLanguage === e.idLanguage);
                    this.props.handleChangeLang(_LangConstant ? _LangConstant.lang : DefaultLangConstant.lang);
                }}
                placeholder={this.props.lang.language}
                lang={this.props.lang}
            />
        );
    }
}
