import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "../../scss/components/NavbarComponent.scss";
import chooseLanguage from "../../modules/toolbox/utils";
import { EAvailableLang } from "../../constant/International.constant";

export interface ILabelHref {
    label: string | JSX.Element;
    href?: string;
    isActive?: boolean;
    onClick?: () => void;
    id?: string;
}
interface IPropsNavbar {
    logout: () => void;
    displayName: string;
    menuItems: ILabelHref[];
    dropdownItems: ILabelHref[];
    hasLoginButton?: boolean;
    lang?: any;
    currentLang?: EAvailableLang;
}
export default class NavbarComponent extends React.Component<IPropsNavbar> {
    constructor(props: IPropsNavbar) {
        super(props);
    }
    props: IPropsNavbar = {
        logout: (): void => {},
        displayName: "Blandine Barbe",
        menuItems: [],
        dropdownItems: [],
        hasLoginButton: false
    };
    render(): JSX.Element {
        return (
            <Navbar expand="lg">
                <Navbar.Brand href="/">
                    <img className="logo" alt="Logo" src="https://medgostatic.s3.eu-west-3.amazonaws.com/Logo.png" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {this.props.menuItems.length > 0 && (
                        <Nav className="mr-auto">
                            {this.props.menuItems.map(e => (
                                <Nav.Link
                                    href={e.href}
                                    onSelect={e.onClick ? e.onClick : () => {}}
                                    onClick={e.onClick ? e.onClick : () => {}}
                                    active={e.isActive}
                                    id={e.id}
                                >
                                    {e.label}
                                </Nav.Link>
                            ))}
                        </Nav>
                    )}
                    {this.props.displayName && this.props.displayName.length > 0 && (
                        <Nav>
                            <NavDropdown title={this.props.displayName} id="profile">
                                {this.props.dropdownItems.map(e => (
                                    <NavDropdown.Item
                                        href={e.href}
                                        onClick={e.onClick ? e.onClick : () => {}}
                                        id={e.id}
                                    >
                                        {e.label}
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                        </Nav>
                    )}
                    {this.props.hasLoginButton === true && (
                        <Nav>
                            <Nav.Link href={"/login"} className="btn btn-primary" id="login">
                                {
                                    chooseLanguage(
                                        this.props.lang,
                                        this.props.currentLang ? this.props.currentLang : EAvailableLang.fr
                                    ).connection
                                }
                            </Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
