import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "../../scss/components/VerticalMenuComponent.scss";

interface ILabelHref {
    label: string | JSX.Element;
    logo?: string;
    href?: string;
    isActive?: boolean;
    onClick?: () => void;
}
interface IPropsVerticalMenu {
    menuItems: ILabelHref[];
    className?: string;
    title?: string;
    selectComponent?: JSX.Element;
}

export default class VerticalMenuComponent extends React.Component<IPropsVerticalMenu> {
    static defaultProps: Partial<IPropsVerticalMenu> = {
        menuItems: [],
        className: "flex-column verticalMenu",
        title: "",
        selectComponent: <></>,
    };
    render(): JSX.Element {
        return (
            <Navbar className="navbar-fullsize">
                <Nav defaultActiveKey="/home" className={this.props.className}>
                    <div className="navbar-title">{this.props.title}</div>
                    {this.props.selectComponent}
                    {this.props.menuItems.map(
                        (e: ILabelHref): JSX.Element => (
                            <Nav.Link
                                className="vertical-nav"
                                href={e.href}
                                onSelect={e.onClick ? e.onClick : () => {}}
                                onClick={e.onClick ? e.onClick : () => {}}
                                active={e.isActive}
                            >
                                <i className={`fa margin-right-s ${e.logo ? e.logo : ""}`} aria-hidden={"true"}></i>
                                {"    "}
                                {e.label}
                            </Nav.Link>
                        )
                    )}
                </Nav>
            </Navbar>
        );
    }
}
