import React from "react";
import "../../scss/components/HubloTextAreaComponent.scss";

interface IPropsHubloTextArea {
    label?: string;
    onChange: (e: string) => void;
    value: string;
    placeholder: string;
}

class HubloTextArea extends React.Component<IPropsHubloTextArea> {
    render() {
        return (
            <div>
                <p className="text-area-label">{this.props.label}</p>
                <textarea
                    rows={5}
                    className={`text-area medgo-input-focus ${this.props.value ? " border-blue-hublo " : ""}`}
                    onChange={e => {
                        // @ts-ignore
                        this.props.onChange(e.target.value);
                    }}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                />
            </div>
        );
    }
}
export default HubloTextArea;
