import * as React from "react";
import "../../scss/components/notification/notification.scss";
interface IPropsNotificationView {
    text: string;
    icon: string;
    color: "primary" | "success" | "info" | "warning" | "danger" | "link";
    handleHide: () => void;
    animation: string;
}

export default class NotificationView extends React.Component {
    props: IPropsNotificationView = {
        text: "",
        icon: "",
        handleHide: (): void => {},
        color: "success",
        animation: ""
    };
    render() {
        return (
            <div className={`notification is-${this.props.color}`} style={{ animation: this.props.animation }}>
                <div className="row">
                    <div className={"text"}>{this.props.text}</div>
                </div>
            </div>
        );
    }
}
