import React from "react";

interface IPropsCheckboxMedgo {
    isRadio?: boolean;
    isChecked?: boolean;
    id?: string;
    onClick?: () => void;
}
export default class CheckboxMedgo extends React.Component {
    props: IPropsCheckboxMedgo = {
        isRadio: true,
        isChecked: true,
        onClick: () => {}
    };
    render(): JSX.Element {
        return (
            <div
                className={`${this.props.isRadio ? "circle" : ""} pretty success smooth`}
                onClick={this.props.onClick}
            >
                <input
                    type={this.props.isRadio ? "radio" : "checkbox"}
                    checked={this.props.isChecked}
                    id={`${this.props.id}`}
                />
                <label>
                    <i className="fa fa-check" />
                </label>
            </div>
        );
    }
}
