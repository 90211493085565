import React from "react";
import MedgoInputText from "./MedgoInputText.view";
import "../../scss/components/HubloInputTextIcon.scss";

interface IPropsHubloInputTextIcon {
    id: string;
    placeholder: string;
    onChange: (value: string | null) => void;
    value?: string;
    classNameInput?: string;
    classNameIcon?: string;
    type?: "text" | "email";
    regexPattern?: string;
    isRequired?: boolean;
}

export default class HubloInputTextIcon extends React.Component<IPropsHubloInputTextIcon> {
    static defaultProps: Partial<IPropsHubloInputTextIcon> = {
        type: "text",
        isRequired: false,
    };
    render() {
        return (
            <div style={{ position: "relative" }}>
                <i className={`${this.props.classNameIcon} input-icon-fa`}></i>
                <MedgoInputText
                    id={this.props.id}
                    className={`${this.props.classNameInput} input-icon-text`}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    type={this.props.type}
                    regexPattern={this.props.regexPattern}
                    isRequired={this.props.isRequired}
                />
            </div>
        );
    }
}
