import React, { Component } from "react";
import "../../scss/components/MedgoTabs.scss";
interface IMedgoTabElement {
    label: string;
    badgeValue?: number;
    isLoadingBadgeValue?: boolean;
    isActive?: boolean;
    handleClick?: () => void;
    id?: string;
}
interface IPropsMedgoTabs {
    elements: IMedgoTabElement[];
    id?: string;
}
export default class MedgoTabs extends Component {
    props: IPropsMedgoTabs = {
        elements: []
    };
    render(): JSX.Element {
        return (
            <div className="row" id={this.props.id}>
                {this.props.elements.map(
                    (e): JSX.Element => (
                        <MedgoTabElement key={e.label} element={e} />
                    )
                )}
            </div>
        );
    }
}

function MedgoTabElement({ element }: { element: IMedgoTabElement }): JSX.Element {
    return (
        <div className="col-12 col-sm-3 margin-bottom-m" id={element.id}>
            <div className={`text-center`}>
                <span
                    className={`medgo-tab-element is-pointer ${element.isActive ? "is-active" : ""}`}
                    onClick={element.handleClick}
                >
                    {element.label}
                </span>
                {element.isLoadingBadgeValue === true ? (
                    <span className="badge medgo-tab-badge btn-danger">
                        <i className="fa fa-spinner fa-spin" />
                    </span>
                ) : element.badgeValue && element.badgeValue > 0 ? (
                    <span className="medgo-tab-badge badge btn-danger">{element.badgeValue}</span>
                ) : null}
            </div>
        </div>
    );
}
