import React from "react";

interface IPropsHubloMessageInfo {
    isSpinner?: boolean;
    infoMessage?: any;
}

class HubloMessageInfo extends React.Component<IPropsHubloMessageInfo> {
    render(): JSX.Element {
        if (this.props.isSpinner) {
            return (
                <p className="message-info-modal text-center">
                    <i className="fa fa-spinner fa-spin fa-fw" />
                </p>
            );
        } else if (this.props.infoMessage) {
            return (
                <p className="message-info-modal text-center">
                    <i className="fa fa-check-circle" /> {this.props.infoMessage}
                </p>
            );
        } else {
            return <p className="message-info-modal text-center" />;
        }
    }
}

export default HubloMessageInfo;
