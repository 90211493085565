import React from "react";
import "../../scss/components/inputs/CheckboxHublo.scss";

interface IPropsCheckboxHublo {
    isChecked?: boolean;
    onClick: () => void;
    id?: string;
    hasError?: boolean;
}

export default class CheckboxHublo extends React.Component<IPropsCheckboxHublo> {
    render() {
        return (
            <div className={`hublocheck ${this.props.hasError ? "has-error" : ""}`} onClick={this.props.onClick}>
                <input
                    type="checkbox"
                    checked={this.props.isChecked ? this.props.isChecked : false}
                    id={`${this.props.id}`}
                />
            </div>
        );
    }
}
