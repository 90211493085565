import React, { Component } from "react";
import "../../scss/components/buttons/ButtonMedgoAction.scss";

export enum EButtonMedgoActionClassName {
    medgoButtonPrimary = "btn btn-primary",
    medgoButtonDefault = "btn btn-default",
    medgoButtonDanger = "btn btn-danger",
    medgoButtonWarning = "btn btn-warning",
    medgoButtonSucess = "btn btn-success",
    medgoButtonFull = "btn btn-full",
    medgoButtonOrange = "btn btn-orange"
}

export enum EButtonMedgoActionIcon {
    plus = "fa-1x fa fa-plus"
}
interface IPropsButtonMedgoAction {
    label: string;
    icon?: EButtonMedgoActionIcon;
    handleClick?: () => void;
    className?: EButtonMedgoActionClassName;
    isLoading?: boolean;
}

export default class ButtonMedgoAction extends Component<IPropsButtonMedgoAction> {
    static defaultProps: Partial<IPropsButtonMedgoAction> = {
        label: "",
        className: EButtonMedgoActionClassName.medgoButtonPrimary,
        isLoading: false
    };

    render(): JSX.Element {
        return (
            <button
                className={`hublo-action-button ${this.props.className}`}
                onClick={this.props.handleClick}
                disabled={this.props.isLoading}
            >
                {this.props.icon ? (
                    <i
                        style={{ top: "-2px", paddingRight: "14px", paddingLeft: "4px" }}
                        className={`${this.props.icon}`}
                    />
                ) : null}
                {this.props.isLoading ? (
                    <span className="fas fa-spinner fa-lg fa-spin" />
                ) : (
                    <div className="is-display-inline-block is-position-relative">{this.props.label}</div>
                )}
            </button>
        );
    }
}
