import React from "react";
import "../../scss/components/inputs/Inputs.scss";
import "../../scss/components/inputs/HubloGoogleAutoComplete.scss";
declare const google: any;
interface IPropsHubloGoogleAutoComplete {
    format?: string;
    id?: string;
    placeholder: string;
    handleChange: any;
    onPlaceSelected: any;
    name?: string;
    value?: string;
}

interface IStateHubloGoogleAutoComplete {
    autocomplete: any;
}

class HubloGoogleAutoComplete extends React.Component<IPropsHubloGoogleAutoComplete, IStateHubloGoogleAutoComplete> {
    ref: any;

    constructor(props: IPropsHubloGoogleAutoComplete) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            autocomplete: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.fillInAddress = this.fillInAddress.bind(this);
    }

    componentDidMount() {
        let autocomplete = this.initAutoComplete();
        this.setState({ autocomplete });
    }

    initAutoComplete(): any {
        try {
            const autocomplete = new google.maps.places.Autocomplete(this.ref.current, {
                types: [!this.props.format ? "geocode" : this.props.format]
            });
            autocomplete.addListener("place_changed", this.fillInAddress);
            return autocomplete;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    fillInAddress() {
        const place = this.state.autocomplete.getPlace();
        this.props.onPlaceSelected(place);
    }

    handleChange(event: any) {
        if (this.props.handleChange) {
            this.props.handleChange(event.target.value);
        }
    }

    render(): any {
        return (
            <input
                ref={this.ref}
                name={this.props.name}
                id={this.props.id}
                className="form-control"
                placeholder={this.props.placeholder}
                type="text"
                onChange={this.handleChange}
                value={this.props.value}
            />
        );
    }
}

export default HubloGoogleAutoComplete;
