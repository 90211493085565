import { germanWords } from './de'
import { englishWords } from './en'
import { spanishWords } from './es'
import { frenchWords } from './fr'

export enum EAvailableLang {
    "fr" = "fr",
    "en" = "en",
    "de" = "de",
    "es" = "es",
}

export default function langDictionary(lang: EAvailableLang): any {
    switch (lang) {
        case EAvailableLang.fr:
            return frenchWords;
        case EAvailableLang.de:
            return germanWords;
        case EAvailableLang.en:
            return englishWords;
        case EAvailableLang.es:
            return spanishWords;
        default:
            throw new Error("this lang is not recognized");
    }
}
