import React from "react";
import MedgoInputComponent, { EMedgoInputType } from "./MedgoInput.view";
import SelectCheckboxListComponent, { IOptionsSelectCheckboxList } from "../select/SelectCheckboxList.component";
import SelectComponent, { ISelectLabelValue } from "../select/Select.component";
import { EAvailableLang } from "../../lang/index";
import moment, { Moment } from "moment";
import CheckboxHublo from "../other/CheckboxHublo.view";
import "../../scss/components/inputs/Inputs.scss";

export enum EHubloFormInputType {
    number = "number",
    boolean = "boolean",
    text = "text",
    hour = "hour",
    select = "select",
    selectCheckbox = "selectCheckbox",
    checkbox = "checkbox",
}

interface IPropsHubloFormInput {
    inputType: EHubloFormInputType;
    lang?: any;
    currentLang?: EAvailableLang;
    name: string;
    placeholder: string;
    onChange: (value: string | number | null | Moment | boolean | ISelectLabelValue) => void;
    onBlur?: (target?: EventTarget & HTMLInputElement) => void;
    selectCheckBoxOnChange: (newValues: number[]) => void;
    checkboxOnClick: () => void;
    hasError: boolean;
    value?: string;
    isChecked?: boolean;
    dateValue?: moment.Moment;
    selectValue?: ISelectLabelValue[];
    selectCheckboxValues?: number[];
    selectOptions?: ISelectLabelValue[];
    selectCheckboxOptions?: IOptionsSelectCheckboxList[];
    type?: "text" | "email" | "password";
    min?: number;
    max?: number;
    regexPattern?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    renderText?: (checkedOptions: IOptionsSelectCheckboxList[]) => JSX.Element;
    customText?: JSX.Element;
}

export default class HubloFormInputWithLabelComponent extends React.Component<IPropsHubloFormInput> {
    static defaultProps: Partial<IPropsHubloFormInput> = {
        value: "",
        type: "text",
        isRequired: false,
    };
    render() {
        let component: JSX.Element;
        switch (this.props.inputType) {
            case EHubloFormInputType.hour:
                component = this.renderHourComponent();
                break;
            case EHubloFormInputType.select:
                component = this.renderSelectComponent();
                break;
            case EHubloFormInputType.selectCheckbox:
                component = this.renderSelectCheckboxComponent();
                break;
            case EHubloFormInputType.checkbox:
                component = this.renderCheckboxHubloComponent();
                break;
            default:
                component = this.renderMedgoInputComponent();
        }
        return (
            <>
                {this.props.name !== "" ? (
                    <p className="label-hubloform-input">
                        {this.props.name} {this.props.isRequired ? <span style={{ color: "#FF6B6B" }}>*</span> : null}
                    </p>
                ) : null}
                {component}
            </>
        );
    }
    renderHourComponent() {
        const onChangeFixFormat = (value: string | number | null): void => {
            if (typeof value !== "string") return;
            const fixableRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3])[hH][0-5][0-9]$/;
            if (fixableRegex.test(value)) {
                value = value.replace(/[hH]/, ":");
            }
            this.props.onChange(value);
        };
        return (
            <MedgoInputComponent
                placeholder="hh:mm"
                onChange={onChangeFixFormat}
                onBlur={this.props.onBlur}
                value={this.props.value}
                inputType={EMedgoInputType.text}
                hasError={this.props.hasError}
                isDisabled={this.props.isDisabled}
            />
        );
    }
    renderSelectComponent() {
        return (
            <SelectComponent
                isMulti={false}
                options={this.props.selectOptions}
                onChange={this.props.onChange}
                isClearable={false}
                isSearchable={true}
                value={this.props.selectValue}
                placeholder={this.props.placeholder}
                hasError={this.props.hasError}
                isDisabled={this.props.isDisabled}
            />
        );
    }
    renderSelectCheckboxComponent() {
        return (
            <SelectCheckboxListComponent
                hasBadge={false}
                values={this.props.selectCheckboxValues}
                options={this.props.selectCheckboxOptions}
                onChange={this.props.selectCheckBoxOnChange}
                hasSearchBox={true}
                text={this.props.placeholder}
                lang={this.props.lang}
                currentLang={this.props.currentLang}
                className={`form-control is-border-radius-right-only is-border-radius-left-only is-gray ${
                    this.props.hasError ? "has-error" : ""
                }`}
                renderText={this.props.renderText}
                isDisabled={this.props.isDisabled}
                customText={this.props.customText}
                hasArrow={true}
            />
        );
    }
    renderCheckboxHubloComponent() {
        return (
            <CheckboxHublo
                isChecked={this.props.isChecked}
                onClick={this.props.checkboxOnClick}
                id={this.props.name}
                hasError={this.props.hasError}
            />
        );
    }
    renderMedgoInputComponent() {
        return (
            <MedgoInputComponent
                value={this.props.value ? this.props.value : ""}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                id={this.props.name}
                inputType={
                    this.props.inputType === EHubloFormInputType.boolean
                        ? EMedgoInputType.boolean
                        : this.props.inputType === EHubloFormInputType.number
                        ? EMedgoInputType.number
                        : EMedgoInputType.text
                }
                lang={this.props.lang}
                currentLang={this.props.currentLang}
                placeholder={this.props.placeholder}
                type={this.props.type}
                min={this.props.min}
                max={this.props.max}
                regexPattern={this.props.regexPattern}
                isRequired={this.props.isRequired}
                hasError={this.props.hasError}
                isDisabled={this.props.isDisabled}
            />
        );
    }
}
