import React from "react";

interface ILabelValueString {
    value: string;
    label: string;
}

interface IPropsRadioListComponent {
    handleChange: (value: string) => void;
    value: string;
    options: ILabelValueStringWithStrongPart[];
    name?: string;
    id?: string;
}

interface ILabelValueStringWithStrongPart extends ILabelValueString {
    strongLabel?: string;
    child?: JSX.Element;
}

interface IStateRadioListComponent {
    name: string;
}

class RadioListComponent extends React.Component<IPropsRadioListComponent, IStateRadioListComponent> {
    static defaultProps: Partial<IPropsRadioListComponent> = {
        handleChange: value => {},
        value: "",
        options: []
    };
    static idUnique = 0;
    constructor(props: IPropsRadioListComponent) {
        super(props);
        RadioListComponent.idUnique++;
        this.state = { name: "nameRadioListComponent" + RadioListComponent.idUnique };
    }
    render() {
        if (this.props.options.length > 1) {
            return (
                <div className="row">
                    {this.props.options.map((e: ILabelValueStringWithStrongPart) => (
                        <div className="col-12" key={e.value}>
                            <input
                                name={this.state.name}
                                type="radio"
                                checked={this.props.value === e.value}
                                onChange={() => this.props.handleChange(e.value)}
                                style={{ display: "inline" }}
                            />
                            <label
                                className="comet is-pointer"
                                style={{ display: "inline" }}
                                onClick={() => this.props.handleChange(e.value)}
                            >
                                {e.strongLabel ? (
                                    <strong className="gray-medium-text">{e.strongLabel} : </strong>
                                ) : null}
                                {e.label}
                            </label>
                            {this.props.value === e.value ? e.child : null}
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="row">
                    {this.props.options.map((e: ILabelValueStringWithStrongPart) => (
                        <div className="col-12" key={e.value}>
                            {e.child}
                        </div>
                    ))}
                </div>
            );
        }
    }
}

export default RadioListComponent;
