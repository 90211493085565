/**
 *
 * format a string as 06.00.00.00.00
 * @export
 * @param {string} phoneNumber
 * @returns {string}
 */
export function phoneNumberWithoutCountryCode(phoneNumber: string): string {
    let auxPhoneNumber = phoneNumber.replace("+33", "0");
    auxPhoneNumber = auxPhoneNumber.replace('+41','0');
    auxPhoneNumber = auxPhoneNumber.replace('+49','0');
    return auxPhoneNumber;
}
