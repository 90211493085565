export const frenchWords = {
    yes: "Oui",
    no: "Non",
    previous: "Précédent",
    next: "Suivant",
    noResult: "Aucun Résultat",
    missionAcceptedOnAt: "Mission acceptée le __VAR1 à __VAR2",
    phone: "Téléphone",
    noNumber: "Pas de numéro",
    search: "Rechercher",
    allCheckboxes: "Toutes les cases",
    all: "Tous",
    pages: "pages",
    weAreConnected: "nous sommes connectés",
    languages: "langues",
    contact: "contact",
    allRightsReserved: "Tous droits réservés.",
    TermsOfService: "Conditions générales d'utilisation",
    customerPartners: "Clients/Partenaires",
    solution: "Solution",
    about: "À propos",
    blog: "Blog",
    whitePapers: "Livres blancs",
    connection: "Connexion"
};
