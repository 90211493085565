import React from "react";
import "../../scss/components/shared/common/PaginationFA.scss";

interface IPropsPagination {
    handleClickPrevious: () => void;
    handleClickNext: () => void;
    elementTypeNameNone?: string;
    numberOfElementsDisplayed: number;
    totalNumberOfElements: number;
    pageNumber: number;
    maximumOfElementsToBeDisplay: number;
    isHiddenWhenNoElements?: boolean;
}

export default class PaginationComponent extends React.Component {
    props: IPropsPagination = {
        elementTypeNameNone: "",
        handleClickNext: (): void => {},
        handleClickPrevious: (): void => {},
        numberOfElementsDisplayed: -1,
        totalNumberOfElements: -1,
        pageNumber: 1,
        maximumOfElementsToBeDisplay: 10,
    };
    static defaultProps: Partial<IPropsPagination> = {
        isHiddenWhenNoElements: true,
        pageNumber: -1,
        totalNumberOfElements: -1,
    };
    render(): JSX.Element | null {
        const totalNumberOfElements = this.props.totalNumberOfElements ? this.props.totalNumberOfElements : 0;
        const pageNumber = this.props.pageNumber ? this.props.pageNumber : 0;
        const maximumOfElementsToBeDisplay = this.props.maximumOfElementsToBeDisplay
            ? this.props.maximumOfElementsToBeDisplay
            : 0;
        const hasNextPage: boolean =
            totalNumberOfElements > 0 &&
            (pageNumber - 1) * maximumOfElementsToBeDisplay + this.props.numberOfElementsDisplayed <
                totalNumberOfElements;
        const numberOfElementsDisplayedStart = maximumOfElementsToBeDisplay * (pageNumber - 1) + 1;
        return this.props.isHiddenWhenNoElements === true && totalNumberOfElements === 0 ? null : (
            <nav aria-label="...">
                {totalNumberOfElements > 0 ? (
                    <ul className="pagerFA">
                        <li>
                            <p className="is-display-inline-block">
                                {totalNumberOfElements === 0 ? 0 : numberOfElementsDisplayedStart}
                                {` - ${
                                    this.props.numberOfElementsDisplayed +
                                    maximumOfElementsToBeDisplay * (pageNumber - 1)
                                } de ${totalNumberOfElements}`}
                            </p>
                        </li>
                        <li className={pageNumber > 1 ? "" : "disabled"}>
                            <a onClick={this.props.handleClickPrevious}>
                                <i className="fa fa-chevron-left"></i>
                            </a>
                        </li>
                        <li className={hasNextPage ? "" : "disabled"}>
                            <a onClick={this.props.handleClickNext}>
                                <i className="fa fa-chevron-right"></i>
                            </a>
                        </li>
                    </ul>
                ) : (
                    <ul className="pagerFA">
                        <li className="text-center">{this.props.elementTypeNameNone}</li>
                    </ul>
                )}
            </nav>
        );
    }
    shouldComponentUpdate(nextProp: IPropsPagination): boolean {
        return (
            nextProp.maximumOfElementsToBeDisplay !== this.props.maximumOfElementsToBeDisplay ||
            nextProp.numberOfElementsDisplayed !== this.props.numberOfElementsDisplayed ||
            nextProp.pageNumber !== this.props.pageNumber ||
            nextProp.totalNumberOfElements !== this.props.totalNumberOfElements ||
            nextProp.elementTypeNameNone !== this.props.elementTypeNameNone ||
            nextProp.isHiddenWhenNoElements !== this.props.isHiddenWhenNoElements
        );
    }
}
