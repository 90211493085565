import React from "react";
import "../../scss/components/HeaderComponent.scss";

interface IPropsHeader {
    children?: JSX.Element;
}
export default class Header extends React.Component {
    props: IPropsHeader = {};
    render(): JSX.Element {
        return <div className="header">{this.props.children}</div>;
    }
}
