export {
    ILangConstant,
    LangConstants,
    DefaultLangConstant,
    ICountryCodeConstant,
    CountryCodeConstants,
    EAvailableLang,
    EAvailableCountryCode,
};

enum EAvailableLang {
    "fr" = "fr",
    "de" = "de",
    "en" = "en",
    "es" = "es",
}

enum EAvailableCountryCode {
    "fr" = 33,
    "ch" = 41,
    "de" = 49,
    "es" = 34,
    "be" = 32,
    "it" = 39,
    "uk" = 44,
    "lu" = 352,
}

interface ILangConstant {
    name: string;
    lang: EAvailableLang;
    idLanguage: number;
    fa: string;
    countryFlag: string;
    logoFlag: string;
}

interface ICountryCodeConstant {
    countryCode: number;
    countryName: string;
    countryFlag: string;
    countryPhoneLength: number;
}

const LangConstants: ILangConstant[] = [
    {
        countryFlag: "🇫🇷",
        fa: "",
        idLanguage: 1,
        lang: EAvailableLang.fr,
        name: "Français",
        logoFlag: "/images/icons/france-flag-round.png",
    },
    {
        countryFlag: "🇬🇧",
        fa: "",
        idLanguage: 2,
        lang: EAvailableLang.en,
        name: "English",
        logoFlag: "/images/icons/uk-flag-round.png",
    },
    {
        countryFlag: "🇩🇪",
        fa: "",
        idLanguage: 3,
        lang: EAvailableLang.de,
        name: "Deutsch",
        logoFlag: "/images/icons/germany-flag-round.png",
    },
    {
        countryFlag: "🇪🇸",
        fa: "",
        idLanguage: 4,
        lang: EAvailableLang.es,
        name: "Spanish",
        logoFlag: "/images/icons/france-flag-round.png",
    },
];

const DefaultLangConstant = LangConstants[0];

const CountryCodeConstants: ICountryCodeConstant[] = [
    {
        countryCode: EAvailableCountryCode.fr,
        countryFlag: "🇫🇷",
        countryName: "France",
        countryPhoneLength: 9, // Length after the first 0
    },
    {
        countryCode: EAvailableCountryCode.de,
        countryFlag: "🇩🇪",
        countryName: "Deutschland",
        countryPhoneLength: 10,
    },
    {
        countryCode: EAvailableCountryCode.ch,
        countryFlag: "🇨🇭",
        countryName: "Suisse",
        countryPhoneLength: 9,
    },
    {
        countryCode: EAvailableCountryCode.es,
        countryFlag: "🇪🇸",
        countryName: "Espagne",
        countryPhoneLength: 9,
    },
    {
        countryCode: EAvailableCountryCode.be,
        countryFlag: "🇧🇪",
        countryName: "Belgique",
        countryPhoneLength: 9,
    },
    {
        countryCode: EAvailableCountryCode.it,
        countryFlag: "🇮🇹",
        countryName: "Italie",
        countryPhoneLength: 10,
    },
    {
        countryCode: EAvailableCountryCode.uk,
        countryFlag: "🇬🇧",
        countryName: "Grande-Bretagne",
        countryPhoneLength: 9,
    },
    {
        countryCode: EAvailableCountryCode.lu,
        countryFlag: "🇱🇺",
        countryName: "Luxembourg",
        countryPhoneLength: 9,
    },
];
