import React from "react";

interface IPropsMedgoInputNumber {
    id: string;
    placeholder: string;
    onChange: (value: string | number | null) => void;
    onBlur?: (target: EventTarget & HTMLInputElement) => void;
    value?: number;
    className?: string;
    step?: number;
    min?: number;
    max?: number;
    isRequired?: boolean;
    hasError?: boolean;
}

export default class MedgoInputNumber extends React.Component<IPropsMedgoInputNumber> {
    static defaultProps: Partial<IPropsMedgoInputNumber> = {
        className: "form-control",
        step: 1,
        isRequired: false
    };
    render() {
        return (
            <input
                id={this.props.id}
                type="number"
                step={String(this.props.step)}
                className={`${this.props.className} ${this.props.hasError ? "has-error" : ""}`}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={e => {
                    e.target.setCustomValidity("");
                    this.props.onChange(e.target.value);
                }}
                onBlur={e => {
                    if (this.props.onBlur) this.props.onBlur(e.target);
                }}
                min={this.props.min === undefined ? "" : this.props.min}
                max={this.props.max === undefined ? "" : this.props.max}
                required={this.props.isRequired}
                onInvalid={(value: any): void => value.target.setCustomValidity(" ")}
            />
        );
    }
}
