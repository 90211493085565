import React, { Component } from "react";
import "../../scss/components/buttons/ButtonWithOptionsComponent.scss";
interface IStateButtonWithOptionsComponent {
    isOpen: boolean;
}
interface IPropsButtonWithOptionsComponent {
    label: string;
    options: { label: string; handleClick: () => void }[];
    onClick?: () => void;
}
export default class extends Component<IPropsButtonWithOptionsComponent, IStateButtonWithOptionsComponent> {
    state: IStateButtonWithOptionsComponent = {
        isOpen: false
    };
    render(): JSX.Element {
        return (
            <div className="btn-group btn-block">
                <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={
                        this.props.onClick
                            ? this.props.onClick
                            : (): void => {
                                  this.setState({ isOpen: !this.state.isOpen }, (): void => {
                                      if (this.state.isOpen) {
                                          document.body.addEventListener(
                                              "click",
                                              (): void => {
                                                  setTimeout(() => {
                                                      this.setState({ isOpen: false });
                                                  }, 10);
                                              },
                                              {
                                                  once: true
                                              }
                                          );
                                      }
                                  });
                              }
                    }
                >
                    {this.props.label}
                </button>
                <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={(): void => {
                        this.setState({ isOpen: !this.state.isOpen }, (): void => {
                            if (this.state.isOpen) {
                                document.body.addEventListener(
                                    "click",
                                    (): void => {
                                        setTimeout(() => {
                                            this.setState({ isOpen: false });
                                        }, 10);
                                    },
                                    {
                                        once: true
                                    }
                                );
                            }
                        });
                    }}
                >
                    <span className="caret" />
                    <span className="sr-only">Toggle Dropdown</span>
                </button>
                <ul
                    className={`dropdown-menu options-dropdown has-padding is-float-right ${
                        this.state.isOpen ? "show" : ""
                    }`}
                >
                    {this.props.options.map(
                        (e: { label: string; handleClick: () => void }): JSX.Element => (
                            <li className="is-pointer" key={"ButtonWithOptions".concat("__" + Math.random(), e.label)}>
                                <span onClick={e.handleClick}> {e.label}</span>
                            </li>
                        )
                    )}
                </ul>
            </div>
        );
    }
}
