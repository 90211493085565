import React, { Component } from "react";
import "../../scss/components/buttons/ResetButtonComponent.scss";
interface IPropsResetButtonComponent {
    handleClick: () => void;
    content: JSX.Element;
}
export default class ResetButtonComponent extends Component {
    props: IPropsResetButtonComponent = {
        handleClick: (): void => {},
        content: <span />
    };
    render(): JSX.Element {
        return (
            <button className="has-border-radius reset-button" onClick={this.props.handleClick}>
                {this.props.content}
            </button>
        );
    }
}
