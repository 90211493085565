import React from "react";
import HubloGoogleAutoComplete from "./HubloGoogleAutoComplete.view";
import MedgoLabelComponent, { EMedgoLabelClassName } from "../other/MedgoLabel.view";
import "../../scss/components/inputs/Inputs.scss";
import "../../scss/components/inputs/HubloGoogleAutoComplete.scss";

interface IPropsHubloGoogleAddressAutoComplete {
    id?: string;
    placeholder: string;
    label: string;
    handleChange: any;
    onSelectAddress: any;
    name?: string;
    format?: string;
    value?: string;
}

class HubloGoogleAddressAutoComplete extends React.Component<IPropsHubloGoogleAddressAutoComplete> {
    render() {
        return (
            <div className="hublo-address-input">
                <MedgoLabelComponent className={EMedgoLabelClassName.medgoLabelFormLabel} text={this.props.label} />
                <HubloGoogleAutoComplete
                    name={this.props.name}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    onPlaceSelected={(place: any) => {
                        onPlaceSelected(place, this.props.onSelectAddress);
                    }}
                    handleChange={this.props.handleChange}
                    value={this.props.value}
                    format={this.props.format}
                />
            </div>
        );
    }
}

function onPlaceSelected(place: any, onSelectAddress: any) {
    if (place) {
        let result: any;
        result = {};
        if (place.address_components) {
            const postalCode = place.address_components[place.address_components.length - 1].long_name;
            result["postalCode"] = postalCode;
        }
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            result["address"] = place.formatted_address;
            result["lat"] = lat;
            result["lng"] = lng;
        }
        onSelectAddress(result);
    }
}

export default HubloGoogleAddressAutoComplete;
