import React from "react";
import HubloTextArea from "./HubloTextArea.component";

interface IPropsHubloTextAreaWithLabel {
    label?: string;
    onChange: (e: string) => void;
    value: string;
    placeholder: string;
}

class HubloTextAreaWithLabel extends React.Component<IPropsHubloTextAreaWithLabel> {
    static defaultProps: Partial<IPropsHubloTextAreaWithLabel> = {
        label: ""
    };
    render() {
        return (
            <div>
                <HubloTextArea
                    label={this.props.label}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                />
            </div>
        );
    }
}

export default HubloTextAreaWithLabel;
