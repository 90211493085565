import React from "react";
import { Modal } from "react-bootstrap";
import "../../scss/components/Modal.scss";
interface IPropsModal {
    show: boolean;
    content: JSX.Element;
    footer?: JSX.Element;
    title: string;
    isTitleBold?: boolean;
    handleHide: () => void;
    size?: "lg" | "xl" | "sm";
    dialogClassName?: string;
}
export default class ModalComponent extends React.Component {
    props: IPropsModal = {
        show: false,
        content: <span />,
        handleHide: (): void => {},
        title: "Title desc",
        isTitleBold: false,
        size: "sm",
        dialogClassName: ""
    };
    render(): JSX.Element {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleHide}
                size={this.props.size}
                dialogClassName={this.props.dialogClassName}
            >
                <Modal.Header translate={"fr"} closeButton>
                    <Modal.Title>
                        {this.props.isTitleBold === true ? <strong>{this.props.title}</strong> : this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.content}</Modal.Body>
                <Modal.Footer>{this.props.footer}</Modal.Footer>
            </Modal>
        );
    }
}
