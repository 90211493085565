import React from "react";
import "../../scss/components/shared/common/Pagination.scss";
import { EAvailableLang } from "../../lang/index";
import chooseLanguage from "../../modules/toolbox/utils";
interface IPropsPagination {
    handleClickPrevious: () => void;
    handleClickNext: () => void;
    elementTypeName: string;
    elementTypeNamePlural: string;
    elementTypeNameNone: string;
    numberOfElementsDisplayed: number;
    totalNumberOfElements: number;
    pageNumber: number;
    maximumOfElementsToBeDisplay: number;
    isHiddenWhenNoElements?: boolean;
    lang?: any;
    currentLang?: EAvailableLang;
}

export default class PaginationComponent extends React.Component {
    props: IPropsPagination = {
        elementTypeName: "",
        elementTypeNamePlural: "",
        elementTypeNameNone: "",
        handleClickNext: (): void => {},
        handleClickPrevious: (): void => {},
        numberOfElementsDisplayed: -1,
        totalNumberOfElements: -1,
        pageNumber: 1,
        maximumOfElementsToBeDisplay: 10,
    };
    static defaultProps: Partial<IPropsPagination> = {
        isHiddenWhenNoElements: true,
        pageNumber: -1,
        totalNumberOfElements: -1,
    };
    render(): JSX.Element | null {
        const totalNumberOfElements = this.props.totalNumberOfElements ? this.props.totalNumberOfElements : 0;
        const pageNumber = this.props.pageNumber ? this.props.pageNumber : 0;
        const maximumOfElementsToBeDisplay = this.props.maximumOfElementsToBeDisplay
            ? this.props.maximumOfElementsToBeDisplay
            : 0;
        const hasNextPage: boolean =
            totalNumberOfElements > 0 &&
            (pageNumber - 1) * maximumOfElementsToBeDisplay + this.props.numberOfElementsDisplayed <
                totalNumberOfElements;
        const elementTypeName: string = this.props.elementTypeName ? this.props.elementTypeName : "mission";
        const numberOfElementsDisplayedStart = maximumOfElementsToBeDisplay * (pageNumber - 1) + 1;
        return this.props.isHiddenWhenNoElements === true && totalNumberOfElements === 0 ? null : (
            <nav aria-label="...">
                {totalNumberOfElements > 0 ? (
                    <ul className="pager">
                        <li className={"previous ".concat(pageNumber > 1 ? "" : "disabled")}>
                            <a onClick={this.props.handleClickPrevious}>
                                {chooseLanguage(this.props.lang, this.props.currentLang).previous}
                            </a>
                        </li>
                        <li className="text-center">
                            <p className="is-display-inline-block">
                                {totalNumberOfElements === 0 ? 0 : numberOfElementsDisplayedStart}
                                {` - ${
                                    this.props.numberOfElementsDisplayed +
                                    maximumOfElementsToBeDisplay * (pageNumber - 1)
                                } / ${totalNumberOfElements}`}{" "}
                                {this.props.numberOfElementsDisplayed > 1
                                    ? this.props.elementTypeNamePlural
                                    : this.props.elementTypeName}
                            </p>
                        </li>
                        <li className={"next ".concat(hasNextPage ? "" : "disabled")}>
                            <a onClick={this.props.handleClickNext}>
                                {chooseLanguage(this.props.lang, this.props.currentLang).next}
                            </a>
                        </li>
                    </ul>
                ) : (
                    <ul className="pager">
                        <li className="text-center">{this.props.elementTypeNameNone}</li>
                    </ul>
                )}
            </nav>
        );
    }
    shouldComponentUpdate(nextProp: IPropsPagination): boolean {
        return (
            nextProp.elementTypeName !== this.props.elementTypeName ||
            nextProp.maximumOfElementsToBeDisplay !== this.props.maximumOfElementsToBeDisplay ||
            nextProp.numberOfElementsDisplayed !== this.props.numberOfElementsDisplayed ||
            nextProp.pageNumber !== this.props.pageNumber ||
            nextProp.totalNumberOfElements !== this.props.totalNumberOfElements ||
            nextProp.currentLang !== this.props.currentLang ||
            nextProp.elementTypeNamePlural !== this.props.elementTypeNamePlural ||
            nextProp.elementTypeNameNone !== this.props.elementTypeNameNone ||
            nextProp.isHiddenWhenNoElements !== this.props.isHiddenWhenNoElements
        );
    }
}
