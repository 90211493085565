import React from "react";
import "../../scss/components/table/HubloExpandTable.scss";

interface IPropsHubloExpandTable {
    id: number;
    name: string;
    subname: string;
    isChecked: boolean;
    hiddenTable: JSX.Element;
    onChange: () => void;
    isVisible: boolean;
    displayHiddenTable: () => void;
}

export default class HubloExpandTable extends React.Component<IPropsHubloExpandTable> {
    render() {
        return (
            <table className="collapsible table">
                <tr>
                    <td className={`checkbox-${this.props.isVisible ? "visible" : "collapse"}`}>
                        <input
                            type="checkbox"
                            className="zoom-checkbox"
                            checked={this.props.isChecked}
                            onClick={this.props.onChange}
                        />
                    </td>
                    <td className="name-style">{this.props.name}</td>
                    <td className="subname-style">{this.props.subname}</td>
                    <td
                        className={`expand-button-${this.props.isVisible ? "visible" : "collapse"}`}
                        onClick={this.props.displayHiddenTable}
                    >
                        {" "}
                        {this.props.isVisible ? "-" : "+"}
                    </td>
                </tr>
                <tr className={`hide-table-padding-${this.props.isVisible ? "visible" : "collapse"}`}>
                    <td colSpan={4}>
                        <div id={`collapse${this.props.id}`}>{this.props.hiddenTable}</div>
                    </td>
                </tr>
            </table>
        );
    }
}
