import React, { Component } from "react";
import "../../scss/components/buttons/RadioButtonComponent.scss";
interface IPropsRadioButtonComponent {
    isChecked: boolean;
    text?: string;
    handleToggle?: () => void;
}
export default class RadioButtonComponent extends Component {
    props: IPropsRadioButtonComponent = {
        isChecked: false
    };
    static defaultProps: Partial<IPropsRadioButtonComponent> = {
        handleToggle: (): void => {}
    };
    render(): JSX.Element {
        const input = (
            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" checked={this.props.isChecked} />
        );
        return (
            <div className="row">
                <div className="col-8">
                    <span className="oneoff-td is-float-left is-vcentered"> {this.props.text} </span>
                </div>
                <div className="col-4">
                    <span className="oneoff-td is-float-right">
                        <div className="onoffswitch" onClick={this.props.handleToggle}>
                            {input}
                            <label className="onoffswitch-label">
                                <span className="onoffswitch-inner" />
                                <span className="onoffswitch-switch" />
                            </label>
                        </div>
                    </span>
                </div>
            </div>
        );
    }
}
