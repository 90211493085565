import React from "react";
import SelectComponent, { ISelectLabelValue } from "./Select.component";

interface IPropsColoredSelectComponent {
    options: ISelectLabelValue[];
    onChange?: (value: ISelectLabelValue) => void;
    value: ISelectLabelValue[];
    placeholder: string;
    isDisabled: boolean;
}

export default class ColoredSelectComponent extends React.Component<IPropsColoredSelectComponent> {
    render(): JSX.Element {
        return (
            <SelectComponent
                isMulti={false}
                options={this.props.options}
                onChange={this.props.onChange}
                value={this.props.value}
                placeholder={this.props.placeholder}
                textColor={"#FFF !important"}
                dropdownIndicatorColor={"#FFF"}
                borderColor={this.props.value[0].color ? this.props.value[0].color : ""}
                backgroundColor={this.props.value[0].color ? this.props.value[0].color : ""}
                isDisabled={this.props.isDisabled}
            />
        );
    }
}
