import React from "react";

export enum EMedgoLabelClassName {
    medgoLabelTitle = "medgo-label-title",
    medgoLabelFormLabel = "medgo-label-form-label"
}

interface IPropsMedgoLabel {
    text: string;
    className: EMedgoLabelClassName;
}

export default class MedgoLabel extends React.Component<IPropsMedgoLabel> {
    render(): JSX.Element {
        switch (this.props.className) {
            case EMedgoLabelClassName.medgoLabelTitle:
                return <h1 className={this.props.className}>{this.props.text}</h1>;

            case EMedgoLabelClassName.medgoLabelFormLabel:
                return <span className={this.props.className}>{this.props.text}</span>;

            default:
                return <span className={this.props.className}>{this.props.text}</span>;
        }
    }
}
