import React, { ChangeEvent } from "react";

import CheckboxMedgo from "../other/CheckboxMedgo.view";
import ButtonWithOverlay from "../button/ButtonWithOverlay.component";
import "../../scss/components/SelectCheckboxList.scss";
import "../../scss/global/typography.scss";
import { isNullOrUndefined } from "util";
import { EAvailableLang } from "../../lang/index";
import chooseLanguage from "../../modules/toolbox/utils";

interface IPropsSelectCheckboxList {
    isReadingOnly?: boolean;
    isCategoryDisabled?: boolean;
    isRadio?: boolean;
    badgeContent?: "all" | "off" | "number";
    name?: string;
    text: string;
    values: number[];
    onChange: (newValues: number[]) => void;
    options: IOptionsSelectCheckboxList[];
    hasSearchBox?: boolean;
    className?: string;
    renderText?: (checkedOptions: IOptionsSelectCheckboxList[]) => JSX.Element;
    customText?: JSX.Element;
    hasOptionAll?: boolean;
    hasBadge?: boolean;
    lang?: any;
    currentLang?: EAvailableLang;
    isDisabled?: boolean;
    hasArrow?: boolean;
}

interface IStateSelectCheckboxList {
    searchText: string;
}

export interface IOptionsSelectCheckboxList {
    label: string;
    isCategory?: boolean;
    values: number[];
    isBold?: boolean;
}

class SelectCheckboxList extends React.Component {
    state: IStateSelectCheckboxList = {
        searchText: ""
    };
    props: IPropsSelectCheckboxList = {
        onChange: (): void => {},
        options: [],
        text: "",
        values: []
    };

    static defaultProps: Partial<IPropsSelectCheckboxList> = {
        className: "",
        hasSearchBox: true,
        badgeContent: "all",
        isCategoryDisabled: false,
        isRadio: false,
        isReadingOnly: false,
        hasOptionAll: true,
        name: `${Math.random()}`,
        hasBadge: true
    };

    handleChangeSearchText(event: ChangeEvent<HTMLInputElement>): void {
        this.setState({ searchText: event.target.value });
    }

    getSelectOption(option: IOptionsSelectCheckboxList): JSX.Element {
        return (
            <div
                className={`row ${this.props.isReadingOnly ? "" : "row-select-checbox-list"}`}
                key={`${this.props.name} ${option.values.join(",")} ${option.isCategory}`}
                onClick={this.toggleOption.bind(this, option)}
            >
                <div className="col-2 is-paddingless is-display-inline-block is-vcentered">
                    {!(option.isCategory && this.props.isCategoryDisabled) && this.props.isReadingOnly === false && (
                        <CheckboxMedgo
                            isRadio={this.props.isRadio}
                            id={`${this.props.name} ${option.values.join(",")}`}
                            isChecked={this.isChecked(option.values)}
                        />
                    )}
                </div>
                <div
                    className={`col-10 is-display-inline-block is-vcentered text-left ${
                        this.props.isReadingOnly ? "" : "has-padding-left"
                    }`}
                >
                    <div className={option.isCategory ? "is-bold" : ""}>{option.label}</div>
                </div>
            </div>
        );
    }

    isChecked(optionValues: number[]): boolean {
        return (
            this.props.values.length > 0 &&
            optionValues
                .map((e): boolean => this.props.values.indexOf(e) !== -1)
                .reduce((p, c): boolean => p && c, true)
        );
    }

    toggleOption(option: IOptionsSelectCheckboxList): void {
        let isCategoryDisabled = this.props.isCategoryDisabled;
        if (!(option.isCategory && isCategoryDisabled)) {
            let optionValue = option.values;
            let isChecked = this.isChecked(optionValue);
            if (this.props.isRadio) {
                if (!isChecked) {
                    this.props.onChange(optionValue);
                }
            } else {
                if (option.values && Array.isArray(option.values) && option.values.length > 0) {
                    let values = Object.assign(
                        [],
                        this.props.values.filter(
                            (e): boolean =>
                                option.values.map((val): boolean => val !== e).reduce((p, c): boolean => p && c, true)
                        )
                    );
                    if (values.length === this.props.values.length) {
                        values.push(...option.values);
                    } else if (option.values.length > this.props.values.length) {
                        values = Object.assign([], option.values);
                    }
                    this.props.onChange(values);
                } else {
                    let values = Object.assign(
                        [],
                        this.props.values.filter((e): boolean => option.values.indexOf(e) !== -1)
                    );
                    if (values.length === this.props.values.length) {
                        values.concat(option.values);
                    }
                    this.props.onChange(values);
                }
            }
        }
    }

    areAllChecked(): boolean {
        return this.props.options
            .map((e): boolean => this.isChecked(e.values) || (!isNullOrUndefined(e.isCategory) && e.isCategory))
            .reduce((p, c): boolean => p && c, true);
    }

    render(): JSX.Element {
        let options = this.props.options;
        let isReadingOnly = this.props.isReadingOnly;
        let innerOverlay = (
            <div>
                {!isReadingOnly && this.props.hasSearchBox ? (
                    <div className="has-margin-bottom is-medium">
                        <label className="sr-only" htmlFor="searchText">
                            {chooseLanguage(this.props.lang, this.props.currentLang).search}
                        </label>
                        <input
                            type="text"
                            placeholder={chooseLanguage(this.props.lang, this.props.currentLang).search}
                            className="form-control"
                            id="searchText"
                            value={this.state.searchText}
                            onChange={this.handleChangeSearchText.bind(this)}
                        />
                    </div>
                ) : null}
                {this.hasOptionAll() === true
                    ? this.getSelectOption({
                          label: chooseLanguage(this.props.lang, this.props.currentLang).allCheckboxes,
                          isCategory: true,
                          values: this.props.options
                              .map((e): number[] => e.values)
                              .reduce((p, c): number[] => p.concat(c), []),
                          isBold: true
                      })
                    : null}
                {options
                    .filter(
                        (e): boolean =>
                            this.state.searchText === "" ||
                            e.label.toLocaleLowerCase().indexOf(this.state.searchText.toLocaleLowerCase()) !== -1
                    )
                    .map(this.getSelectOption.bind(this))}
            </div>
        );

        let text = this.renderText();

        if (this.props.hasArrow) {
            text = (
                <div className="input-custom">
                    {text}
                    <div className="selectcheckbox-arrow">
                        <i className="far fa-angle-down fa-5x" />
                    </div>
                </div>
            );
        }

        return (
            <ButtonWithOverlay
                className={this.props.className}
                text={text}
                innerOverlay={innerOverlay}
                name={this.props.name ? this.props.name : ""}
                isDisabled={this.props.isDisabled}
            />
        );
    }

    hasOptionAll(): boolean {
        let hasOptionAll = this.props.hasOptionAll;
        let isReadingOnly = this.props.isReadingOnly;
        let isNotSearching = !this.state.searchText || this.state.searchText == "";

        let hasCategory = false;
        let options = this.props.options;
        for (let i = 0; i < options.length; i++) {
            if (options[i].isCategory) {
                hasCategory = true;
            }
        }

        return hasOptionAll === true && !isReadingOnly && isNotSearching && !this.props.isRadio && !hasCategory;
    }

    renderText(): JSX.Element {
        let checkedOptions = this.props.options.filter((e): boolean => this.isChecked(e.values) && !e.isCategory);

        if (this.props.renderText) {
            return this.props.renderText(checkedOptions);
        }
        if (this.props.customText) {
            return this.props.customText;
        }
        let icon = null;
        let length = checkedOptions.length;
        if (this.props.hasBadge === true) {
            if (length > 0) {
                icon = (
                    <span className="badge badge-primary float-right has-margin-left" style={{ top: "2px" }}>
                        {length}
                    </span>
                );
            }

            if (this.areAllChecked()) {
                if (this.props.badgeContent === "all") {
                    icon = (
                        <span className="badge badge-primary float-right has-margin-left" style={{ top: "2px" }}>
                            {chooseLanguage(this.props.lang, this.props.currentLang).all}
                        </span>
                    );
                } else if (this.props.badgeContent === "number") {
                    icon = (
                        <span
                            className="badge badge-primary float-right has-margin-left"
                            style={{ top: "2px", paddingLeft: "7px", paddingRight: "7px", borderRadius: "10px" }}
                        >
                            {length}
                        </span>
                    );
                } else if (this.props.badgeContent === "off") {
                    icon = null;
                }
            }
        }
        return (
            <span>
                <span className="float-left"> {this.props.text}</span> {icon}
            </span>
        );
    }
}

export default SelectCheckboxList;
