import SelectComponent, { ISelectLabelValue } from "./Select.component";

import { ArrayToolbox } from "npm-hublo-toolbox";
import { DeviceToolbox } from "npm-hublo-toolbox";
import React from "react";

export enum ESelectTitleSingleValueClassNameMobile {
    CLASSIC = "medgo-mobile-select",
    MOBILE_LANDING = "form-control-border-bottom"
}
export enum ESelectTitleSingleValueClassNameDesktop {
    CLASSIC = "",
    MOBILE_LANDING = "form-control-border-bottom"
}
interface IPropsSelectTitleSingleValue {
    id: string;
    label?: string;
    value: any;
    onSelectValue: (value: any) => void;
    statut: string;
    isDisabled?: boolean;
    options: ISelectLabelValue[];
    placeholder?: string;
    classNameMobile?: ESelectTitleSingleValueClassNameMobile;
    classNameDesktop?: ESelectTitleSingleValueClassNameDesktop;
    className?: string;
    lang: { noResult: string };
}

export class SelectTitleSingleValue extends React.Component<IPropsSelectTitleSingleValue> {
    static defaultProps: Partial<IPropsSelectTitleSingleValue> = {
        classNameDesktop: ESelectTitleSingleValueClassNameDesktop.CLASSIC,
        classNameMobile: ESelectTitleSingleValueClassNameMobile.CLASSIC,
        isDisabled: false
    };

    render() {
        if (this.props.options.length === 0) {
            return <div />;
        }
        const noResult = this.props.lang.noResult;
        const options = ArrayToolbox.removeDuplicateObjectInArrayObjects(
            this.props.options,
            "value"
        ) as ISelectLabelValue[];
        let className = this.props.className;
        className += DeviceToolbox.isMobile() ? ` ${this.props.classNameMobile}` : ` ${this.props.classNameDesktop}`;
        className += this.props.isDisabled ? ` medgo-mobile-select-disabled` : "";

        return (
            <div className="puppeteer-selector-container">
                {this.props.label && (
                    <span className="text-12">
                        <span className={`text-medgo medgo-grey`}>{this.props.label}</span>{" "}
                    </span>
                )}
                <SelectComponent
                    id={this.props.id}
                    className={className}
                    simpleValue={true}
                    value={options.filter(option => this.props.value === option.value)}
                    options={options}
                    onChange={(val: ISelectLabelValue): void => {
                        if (!this.props.isDisabled) {
                            this.props.onSelectValue(val ? val.value : null);
                        }
                    }}
                    placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
                    noOptionsMessage={() => noResult}
                />
            </div>
        );
    }
}
