import React from "react";
import SelectComponent, { ISelectLabelValue } from "../select/Select.component";
import { EAvailableLang } from "../../lang/index";
import chooseLanguage from "../../modules/toolbox/utils";

interface IPropsMedgoInputBoolean {
    currentLang?: EAvailableLang;
    lang?: any;
    id: string;
    placeholder: string;
    onChange: (value: string | number | null) => void;
    onBlur?: () => void;
    value: "true" | "false" | null;
    hasError?: boolean;
}

export default class MedgoInputBoolean extends React.Component<IPropsMedgoInputBoolean> {
    render() {
        const options: ISelectLabelValue[] = [
            {
                label: chooseLanguage(this.props.lang, this.props.currentLang).yes,
                value: "true"
            },
            {
                label: chooseLanguage(this.props.lang, this.props.currentLang).no,
                value: "false"
            }
        ];
        return (
            <SelectComponent
                id={this.props.id}
                simpleValue={true}
                isMulti={false}
                value={options.filter(option => this.props.value === option.value)}
                options={options}
                onChange={(val): void => {
                    this.props.onChange(val ? val.value : null);
                }}
                onBlur={() => {
                    if (this.props.onBlur) this.props.onBlur();
                }}
                placeholder={this.props.placeholder}
                noOptionsMessage={inputValue => chooseLanguage(this.props.lang, this.props.currentLang).noResult}
                isClearable={false}
                hasError={this.props.hasError}
            />
        );
    }
}
