import React from "react";
import "../../scss/components/messages/HubloMessage.scss";

interface IPropsHubloErrorMessage {
    label: any;
    type: string;
}

class HubloErrorMessage extends React.Component<IPropsHubloErrorMessage> {
    static defaultProps = {
        type: ""
    };
    render(): JSX.Element {
        return <p className={`hublo-error ${this.props.type}`}>{this.props.label}</p>;
    }
}

export default HubloErrorMessage;
