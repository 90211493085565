import React from "react";
import "../../scss/components/inputs/Inputs.scss";

interface IPropsMedgoInputText {
    id: string;
    placeholder: string;
    onChange: (value: string | null) => void;
    hasError: boolean;
    onBlur?: (target: EventTarget & HTMLInputElement) => void;
    value?: string;
    className?: string;
    type?: "text" | "email" | "password";
    regexPattern?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
}

export default class MedgoInputText extends React.Component<IPropsMedgoInputText> {
    static defaultProps: Partial<IPropsMedgoInputText> = {
        className: "form-control",
        type: "text",
        isRequired: false
    };
    render() {
        return (
            <input
                id={this.props.id}
                type={this.props.type}
                className={`${this.props.className} ${this.props.hasError ? "has-error" : ""}`}
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={e => {
                    e.target.setCustomValidity("");
                    this.props.onChange(e.target.value);
                }}
                onBlur={e => {
                    if (this.props.onBlur) this.props.onBlur(e.target);
                }}
                pattern={this.props.regexPattern ? this.props.regexPattern : ".*"}
                required={this.props.isRequired}
                onInvalid={(value: any): void => value.target.setCustomValidity(" ")}
                disabled={this.props.isDisabled ? this.props.isDisabled : false}
            />
        );
    }
}
