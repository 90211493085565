import React, { Component } from "react";
import "../../scss/components/buttons/HubloActionButton.scss";

interface IPropsHubloAction {
    label: string;
    icon?: string;
    handleClick?: () => void;
}

export default class HubloAction extends Component {
    props: IPropsHubloAction = {
        label: ""
    };
    render(): JSX.Element {
        return (
            <button className="medgo-action-button" onClick={this.props.handleClick}>
                {this.props.icon ? <i className={`fa-2x ${this.props.icon}`} /> : null}
                <div
                    className="is-display-inline-block is-position-relative"
                    style={{ top: "-6px", paddingLeft: "14px", paddingRight: "4px" }}
                >
                    {this.props.label}
                </div>
            </button>
        );
    }
}
