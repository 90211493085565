export const spanishWords = {
    allCheckboxes: "Todas las casillas",
    blog: "Blog",
    connection: "Iniciar sesión",
    missionAcceptedOnAt: "Misión aceptada el __VAR1 a __VAR2",
    next: "Siguiente",
    noNumber: "No hay número ",
    phone: "Teléfono ",
    previous: "Anterior",
    search: "Buscar",
    yes: "Sí",
    no: "No",
    all: "Todos",
    about: "Sobre nosotros ",
    allRightsReserved: "Todos los derechos reservados ",
    contact: "Contacta con nosotros",
    customerPartners: "Clientes/Socios ",
    languages: "Idiomas ",
    noResult: "Ningún resultado",
    pages: "Páginas ",
    solution: "solución ",
    TermsOfService: "Términos y condiciones de uso ",
    weAreConnected: "estamos conectados ",
    whitePapers: "Libros blancos"
};
