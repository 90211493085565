import React, { Component } from "react";
import "../../scss/components/shared/resumes/ResumeComponent.scss";
interface IElement {
    title: string;
    content: JSX.Element;
}
interface IPropsResumeComponent {
    elements: IElement[];
}
export default class ResumeComponent extends Component {
    props: IPropsResumeComponent = {
        elements: []
    };
    render(): JSX.Element {
        return (
            <div className="resume-component">
                {this.props.elements.map(
                    (e, i, arr): JSX.Element => (
                        <div key={e.title}>
                            <h6>
                                {i + 1}. {e.title}
                            </h6>
                            {e.content}
                            {i < arr.length - 1 ? <hr /> : null}
                        </div>
                    )
                )}
            </div>
        );
    }
}
