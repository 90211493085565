import React from "react";
import HubloMessageInfo from "../modal/HubloMessageInfo.component";
import "../../scss/components/HubloJumbotron.scss";

interface IPropsHubloJumbotron {
    title?: string;
    size?: string;
    innerJumbotron: any;
    infoMessage?: any;
    isNotHr?: boolean;
    isSpinnerInfoMessage?: boolean;
}

class HubloJumbotron extends React.Component<IPropsHubloJumbotron> {
    render(): JSX.Element {
        const innerJumbotron = this.props.innerJumbotron;
        const title = this.props.title;
        const size = this.props.size;

        let sizeClassName = "col-md-8 offset-md-2";
        if (size) {
            switch (size) {
                case "l":
                    sizeClassName = "col-md-10 offset-md-1";
                    break;

                case "xl":
                    sizeClassName = "col-md-12";
                    break;

                case "md":
                    sizeClassName = "col-md-8 offset-md-2";
                    break;

                case "s":
                    sizeClassName = "col-md-6 offset-md-3";
                    break;

                case "xs":
                    sizeClassName = "col-sm-6 offset-sm-3";
                    break;

                default:
                    break;
            }
        }

        sizeClassName = `${sizeClassName} col-12 padding-left-s padding-right-s m-auto`;

        const isNotHr = this.props.isNotHr;
        let hr;
        if (!isNotHr && title) {
            hr = <hr />;
        }

        return (
            <div className="container-fluid row">
                <div className="col">
                    {" "}
                    <div className="row">
                        <div className={sizeClassName}>
                            <div className="jumbotron ">
                                <div className="row padding-left-s padding-right-s">
                                    <div className="col-md-10 offset-md-1 col-12 padding-left-xs padding-right-xs m-auto">
                                        <h2 className="text-center comet">{title}</h2>
                                        {hr}

                                        {innerJumbotron}
                                        <div className="row">
                                            <div className="col-md-8 offset-md-2">
                                                <HubloMessageInfo
                                                    infoMessage={this.props.infoMessage}
                                                    isSpinner={this.props.isSpinnerInfoMessage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HubloJumbotron;
