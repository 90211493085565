import React, { Component } from "react";
import { ListItem, ListItemSecondaryAction, ListItemText, List, IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "../../scss/components/list/VerticalList.scss";

interface Elements {
    name: string;
    id: number;
}

interface IPropsVerticalList {
    handleClick?: () => void;
    handleClickWithId?: (id: number) => void;
    elements: Elements[];
    isListItemEditButton?: boolean;
    isListItemArrowButton: boolean;
}

export default class VerticalList extends Component<IPropsVerticalList> {
    static defaultProps: Partial<IPropsVerticalList> = {
        isListItemEditButton: false
    };

    render(): JSX.Element {
        return (
            <List className={"list"} disablePadding={true}>
                {this.props.elements.map(element => (
                    <ListItem
                        className={"listItem"}
                        button
                        key={element.id}
                        disableRipple
                        disableTouchRipple
                        onClick={
                            this.props.handleClickWithId
                                ? () => this.props.handleClickWithId && this.props.handleClickWithId(element.id)
                                : this.props.handleClick
                        }
                    >
                        <ListItemText
                            classes={{ primary: "cutText" }}
                            primary={element.name}
                            className="listItemTextFont"
                        />
                        {this.props.isListItemEditButton ? (
                            <ListItemSecondaryAction>
                                <IconButton edge="end">
                                    <CreateIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        ) : null}
                        {this.props.isListItemArrowButton ? (
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    onClick={
                                        this.props.handleClickWithId
                                            ? () =>
                                                  this.props.handleClickWithId &&
                                                  this.props.handleClickWithId(element.id)
                                            : this.props.handleClick
                                    }
                                >
                                    <KeyboardArrowRightIcon className="colorArrowIcon" />
                                </IconButton>
                            </ListItemSecondaryAction>
                        ) : null}
                    </ListItem>
                ))}
            </List>
        );
    }
}
