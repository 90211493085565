import React from "react";
import MedgoInputBoolean from "./MedgoInputBoolean.view";
import MedgoInputNumber from "./MedgoInputNumber.view";
import MedgoInputText from "./MedgoInputText.view";
import { EAvailableLang } from "../../lang/index";

export enum EMedgoInputType {
    number = "number",
    boolean = "boolean",
    text = "text",
    password = "password"
}

interface IPropsMedgoInput {
    inputType: EMedgoInputType;
    currentLang?: EAvailableLang;
    lang?: any;
    id: string;
    placeholder: string;
    onChange: (value: string | number | null) => void;
    onBlur?: (target?: EventTarget & HTMLInputElement) => void;
    value: string;
    hasError: boolean;
    type?: "text" | "email" | "password";
    min?: number;
    max?: number;
    regexPattern?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
}

export default class MedgoInputComponent extends React.Component<IPropsMedgoInput> {
    static defaultProps: Partial<IPropsMedgoInput> = {
        type: "text",
        isRequired: false
    };
    render() {
        switch (this.props.inputType) {
            case EMedgoInputType.boolean:
                const booleanValue =
                    this.props.value === "true" ? "true" : this.props.value === "false" ? "false" : null;
                return (
                    <MedgoInputBoolean
                        lang={this.props.lang}
                        currentLang={this.props.currentLang}
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                        value={booleanValue}
                        hasError={this.props.hasError}
                    />
                );

            case EMedgoInputType.number:
                const numberValue = !isNaN(parseInt(this.props.value)) ? parseInt(this.props.value) : undefined;
                return (
                    <MedgoInputNumber
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                        value={numberValue}
                        min={this.props.min}
                        max={this.props.max}
                        isRequired={this.props.isRequired}
                        hasError={this.props.hasError}
                    />
                );

            case EMedgoInputType.text:
                return (
                    <MedgoInputText
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange}
                        onBlur={this.props.onBlur}
                        value={this.props.value}
                        type={this.props.type}
                        regexPattern={this.props.regexPattern}
                        isRequired={this.props.isRequired}
                        hasError={this.props.hasError}
                        isDisabled={this.props.isDisabled}
                    />
                );

            default:
                return <div />;
        }
    }
}
