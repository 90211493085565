import React from "react";
import { Modal } from "react-bootstrap";
import "../../scss/components/HubloModal.scss";

interface IPropsModal {
    show: boolean;
    content: JSX.Element;
    footer?: JSX.Element;
    title: string;
    isTitleBold?: boolean;
    size?: "lg" | "xl" | "sm";
    dialogClassName?: string;
}
export default class ModalComponent extends React.Component {
    props: IPropsModal = {
        show: false,
        content: <span />,
        title: "Title desc",
        isTitleBold: false,
        size: "lg",
        dialogClassName: ""
    };
    render(): JSX.Element {
        return (
            <Modal show={this.props.show} size={this.props.size} dialogClassName={this.props.dialogClassName}>
                {this.props.title !== "" && (
                    <Modal.Header translate="">
                        <Modal.Title>
                            {this.props.isTitleBold === true ? (
                                <strong className="text">{this.props.title}</strong>
                            ) : (
                                this.props.title
                            )}
                        </Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body>{this.props.content}</Modal.Body>
                <Modal.Footer>{this.props.footer}</Modal.Footer>
            </Modal>
        );
    }
}
