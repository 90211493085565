import React from "react";

interface IPropsHubloSpinnerLoading {
    isLoading: boolean;
    color?: string;
}

class HubloSpinnerLoading extends React.Component<IPropsHubloSpinnerLoading> {
    render() {
        let colorClass = "";
        switch (this.props.color) {
            case "blue":
                colorClass = "blueMedgo";
                break;
            case "grey":
                colorClass = "grey";
                break;
            case "white":
                colorClass = "white";
                break;
            case "comet":
                colorClass = "comet";
                break;
            default:
                colorClass = "white";
        }

        return this.props.isLoading ? (
            <span className={colorClass}>
                <i className="fa fa-spinner fa-spin" />
            </span>
        ) : null;
    }
}

export default HubloSpinnerLoading;
