import * as React from "react";
import NotificationView from "./Notification.view";

interface IPropsNotificationComponent {
    text: string;
    icon?: string;
    color?: "primary" | "success" | "info" | "warning" | "danger" | "link";
    disapearsAfter?: number;
    isVisible: boolean;
    handleHide: () => void;
}

interface IStateNotificationComponent {
    animation: "";
    timerId: any;
}

export default class NotificationComponent extends React.Component {
    props: IPropsNotificationComponent = {
        text: "",
        icon: "",
        handleHide: (): void => {},
        color: "primary",
        disapearsAfter: 0,
        isVisible: false,
    };
    state: IStateNotificationComponent = {
        animation: "",
        timerId: null,
    };

    static defaultProps: Partial<IPropsNotificationComponent> = {
        icon: "",
        color: "primary",
        disapearsAfter: 2000,
    };

    componentWillReceiveProps(nextProps: IPropsNotificationComponent) {
        if (this.props.isVisible && nextProps.isVisible) {
            clearTimeout(this.state.timerId);
            this.handleAnimation();
        } else if (!this.props.isVisible && nextProps.isVisible) {
            this.setState(
                {
                    animation: "enter 500ms linear",
                },
                this.handleAnimation
            );
        }
    }

    handleAnimation() {
        let timerId = setTimeout(() => {
            this.setState(
                {
                    animation: "leave 500ms linear",
                },
                () => {
                    setTimeout(() => {
                        this.props.handleHide();
                        this.setState({
                            animation: "",
                        });
                    }, 500);
                }
            );
        }, this.props.disapearsAfter);

        this.setState({
            timerId,
        });
    }

    render() {
        let color = this.props.color ? this.props.color : "primary";

        if (["primary", "success", "info", "warning", "danger", "link"].indexOf(color) === -1) {
            color = "primary";
        }

        return this.props.isVisible ? (
            <NotificationView
                text={this.props.text}
                icon={this.props.icon ? this.props.icon : ""}
                color={color}
                handleHide={this.props.handleHide}
                animation={this.state.animation}
            />
        ) : null;
    }
}
