import React from "react";
import "../../scss/components/HubloFontAwesome.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export enum EMedgoIcon {
    checkCircle = "fa-check-circle",
    timesCircle = "fa-times-circle",
    check = "fa-check",
    times = "fa-times",
    questionCircle = "fa-question-circle"
}

export enum EMedgoColor {
    emerald = "emerald",
    red = "red",
    comet = "comet"
}
interface IPropsHubloFontAwesome {
    icon: EMedgoIcon;
    color: EMedgoColor;
    size?: number;
    tooltipText?: string;
}

export default class HubloFontAwesome extends React.Component<IPropsHubloFontAwesome> {
    render() {
        const classNameSize = `fa-${this.props.size ? this.props.size : 1}x`;
        return this.props.tooltipText ? (
            <OverlayTrigger
                placement="top"
                // @ts-ignore
                overlay={this.props.tooltipText ? <Tooltip id="tooltip">{this.props.tooltipText}</Tooltip> : {}}
            >
                <i className={`fa ${classNameSize} ${this.props.icon} is-${this.props.color}`} />
            </OverlayTrigger>
        ) : (
            <i className={`fa ${classNameSize} ${this.props.icon} is-${this.props.color}`} />
        );
    }
}
