import React from "react";
import Select from "react-select";
import { isMobile } from "../../modules/toolbox/devices";
import { ValueType, OptionsType, ActionMeta } from "react-select/src/types";
import "../../scss/components/SelectComponent.scss";
import { Styles } from "react-select/src/styles";
import { isNullOrUndefined } from "util";

export interface ISelectLabelValue {
    value: string | number;
    label: string;
    color?: string;
    isCategory?: boolean;
}
export interface ISelectCategoryValue {
    label: string;
    options: ISelectLabelValue[];
    id?: string;
}
interface IPropsSelectComponent {
    simpleValue?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
    name?: string;
    value: ISelectLabelValue[];
    options: ISelectLabelValue[] | ISelectCategoryValue[];
    onChange: (val: ISelectLabelValue) => void;
    id?: string;
    placeholder?: string;
    noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | undefined;
    isLoading?: boolean;
    // eslint-disable-next-line
    ref?: any;
    onInputChange?: (val: number | string) => void;
    isSearchable?: boolean;
    className?: string;
    // eslint-disable-next-line
    onMenuOpen?: any;
    styles?: Styles;
    isNative?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    hasError?: boolean;
    textColor?: string;
    backgroundColor?: string;
    dropdownIndicatorColor?: string;
    borderColor?: string;
    isDisabled?: boolean;
}

export default class SelectComponent extends React.Component {
    props: IPropsSelectComponent = {
        onChange: (): void => {
            return;
        },
        onFocus: (): void => {
            return;
        },
        onBlur: (): void => {
            return;
        },
        options: [],
        value: []
    };
    static defaultProps: Partial<IPropsSelectComponent> = {
        isNative: true,
        className: "select-component-container",
        isDisabled: false
    };
    onChange(event: React.FormEvent): void {
        // @ts-ignore
        let value = event.target.value;
        if (this.props.options.length > 0) {
            value = parseInt(value);
        }
        this.props.onChange(
            // @ts-ignore
            this.props.options.find((e): boolean => e.value === value || parseInt(e.value) === value)
        );
    }
    render(): JSX.Element {
        if (isMobile() && this.props.isNative) {
            const value = Array.isArray(this.props.value) ? this.props.value[0] : this.props.value;
            const selectValue =
                "" +
                // @ts-ignore
                (!isNullOrUndefined(value) && !isNullOrUndefined(value.value)
                    ? "" + //
                      // @ts-ignore
                      (Array.isArray(value.value) ? value.value[0] : value.value)
                    : "");
            return (
                <select
                    className={`form-control ${this.props.className} ${this.props.hasError ? "has-error" : ""}`}
                    name={this.props.name ? this.props.name : `${Math.random()}`}
                    id={this.props.id ? `${this.props.id}` : `${Math.random()}`}
                    value={selectValue}
                    defaultValue={selectValue}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange.bind(this)}
                    onBlur={() => {
                        if (this.props.onBlur) this.props.onBlur();
                    }}
                >
                    <option value={""} />
                    {(this.props.options as ISelectLabelValue[])
                        .filter((e): boolean => !e.isCategory)
                        .map(
                            (e: ISelectLabelValue): JSX.Element => (
                                <option value={"" + e.value} key={"" + e.value} selected={selectValue === "" + e.value}>
                                    {e.label}
                                </option>
                            )
                        )}
                </select>
            );
        }
        const backgroundColor = this.props.isDisabled
            ? "#e9ecef"
            : this.props.backgroundColor
            ? this.props.backgroundColor
            : "#FFF";
        const colorStyle = {
            control: (styles: any, state: any) => ({
                ...styles,
                background: backgroundColor,
                borderColor: `${this.props.borderColor ? this.props.borderColor : "#cccccc"}`
            }),
            dropdownIndicator: (styles: any, state: any) => {
                return {
                    ...styles,
                    color: `${this.props.dropdownIndicatorColor ? this.props.dropdownIndicatorColor : "black"}`
                };
            },
            singleValue: (styles: any, state: any) => ({
                ...styles,
                color: `${this.props.textColor ? this.props.textColor : "black"}`
            })
        };
        return (
            <Select
                {...this.props}
                onChange={(val: ValueType<ISelectLabelValue>): void => {
                    //@ts-ignore
                    this.props.onChange(val);
                }}
                classNamePrefix="select-component"
                className={`select-component-container ${this.props.className} ${
                    this.props.hasError ? "has-error" : ""
                }`}
                components={
                    this.props.isDisabled
                        ? {
                              IndicatorSeparator: () => null,
                              DropdownIndicator: () => null
                          }
                        : {
                              IndicatorSeparator: () => null
                          }
                }
                styles={colorStyle}
                isDisabled={this.props.isDisabled}
            />
        );
    }
}
